import React from 'react'
import { NewPageLink } from '../utils/utils'
import Layout from '../components/layout'

const ContactPage = () => (
  <Layout>
    <h1>Contact Information</h1>
    <ul>
      <li><b>E-Mail:</b> [firstname].[lastname]@gmail.com (Replace brackets if you're not a robot)</li>
      <li>
        <b>Telegram:</b>&nbsp;
        <NewPageLink to='https://t.me/shervinkh'>https://t.me/shervinkh</NewPageLink>
      </li>
    </ul>
  </Layout>
)

export default ContactPage
